import React from 'react'
import { Link } from 'gatsby'
import TextLayout from '@layouts/text'

const NotFoundPage: React.FC = () => {
  return (
    <TextLayout title='Access Forbidden'>
      <p>This resource could not be sent.</p>
      <p>
        If you are attempting to hotlink to a JS or CSS file on fullcalendar.io, use the CDN
        instead. See the <Link to='/docs/initialize-globals'>downloads page</Link> for more details.
      </p>
    </TextLayout>
  )
}

export default NotFoundPage
